.dg-results {
  height: 100%;
  .tab-content,
  .tab-pane,
  .row {
    height: 100%;
  }
  margin-top: 20px;
  padding-right: 10px;
  max-width: 800px;
  width: 100%;
  pre {
    height: 100%;
  }
  code {
    height: 100%;
    font-family: menlo, monospace;
    font-size: 16px;
  }
}
