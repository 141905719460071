html,
body,
#root,
.dg-app {
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
}

.dg-app {
  &__container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
  &__wrapper {
    width: 100%;
  }
  &__go {
    margin-top: 20px;
    padding-top: 20px;
    float: right;
    width: 150px;
  }
}
