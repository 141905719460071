.dg-valueattributeselector {
  padding: 20px;
  margin-top: 20px;
  max-width: 800px;
  width: 100%;
  h5 {
    margin-top: 0;
  }
}

.dg-valueattributeselector__statistic {
  padding: 20px;
  margin-top: 20px;
  max-width: 800px;
  width: 100%;
  h5 {
    margin-top: 0;
  }
}

.dg-valueattributeselector__addbutton {
  margin-top: 30px;
  width: 150px;
}
